<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :disabled="readonly"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        hide-details
        :value="value"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      :value="value"
      full-width
      format="24hr"
      @input="$emit('input', $event)"
      @click:minute="$refs.menu.save(value)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "TimeInput",
  props: {
    label: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
</style>
